<template>
  <Teleport to="#body-scroll">
    <div class="vld-parent">
      <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :loader="'dots'" :color="'#36a8dd'" />
    </div>
  </Teleport>
  <div class="accordion h-auto p-3">
    <div class="w-full">
      <h1 class="text-2xl text-gray-900 font-medium dark:text-gray-200">
        Application Control
      </h1>
      <p class="mt-1 text-sm font-normal text-gray-400">
        View and Issue commands to the StatCast3D application
      </p>
      <!-- <button type="submit" class="btn" @click="postPub(null, 'HMMCMD| SOMETHING')">&#x27F3;</button> -->
    </div>


    <div class="grid lg:grid-cols-1 grid-col-1 gap-4 mt-5">
      <div class="
                                                                          card
                                                                          col-span-1
                                                                          grid grid-cols-1
                                                                          gap-5
                                                                          bg-white
                                                                          dark:bg-gray-800
                                                                          w-full
                                                                          rounded-md
                                                                          p-5
                                                                          shadow
                                                                        ">
        <div class="accordion-packed">
          <h2 style="display: flex" class="font-semibold dark:text-gray-200">
            Application Control
            <label style="margin-left: auto" class="switch">
              <input type="checkbox" />
              <span class="slider round" @click="listselect()"></span>
            </label>
          </h2>

          <div v-if="Object.keys(appData).length === 0 && !isLoading"
            style="font-size: large;color: #cdd3d2; justify-content: center; display: flex">
            No Application Instances Currently Active
          </div>
          <div class="wrapper mt-5" v-if="listview == 0">
            <div id="app">
              <ul :class="getClass()">
                <li v-bind:style="card.lostConn == 0
                  ? card.setting || card.control
                    ? settingscard
                    : normal
                  : grey
                  " v-for="(card, index) in appData" v-bind:id="index" class="card-item rounded-md box-border">
                  <div
                    v-if="!card.setting && !card.control && card.gamePK == 0 && store.state.user.organisation == 'MLB'">
                    <div id="Header" style="display: inline-flex; width: 100%">
                      <div style="white-space: nowrap; overflow: hidden; color: #36a8dd;">
                        {{ index }}
                      </div>
                    </div>
                    <img v-if="card.gamePK != 0" v-bind:src="!card.thumb
                      ? require('../assets/headlessLoad.png')
                      : 'data:image/jpeg;base64,' + card.thumb
                      " alt="No Thumbnail" class="squared" />
                    <h1 v-if="card.lostConn == 1">Connection Lost</h1>
                    <div id="teams" v-if="card.lostConn == 0" style="color: #36a8dd">
                      <div v-if="card.lostConn == 0 && appDataOptions[index] != undefined" id="status" v-bind:style="overrideStyles
                        ">
                        <h1>Setup</h1>
                        <div id="LeagueSelection" style="display: inline-flex;width: 100%; padding-top: 5%">
                          <div style="width: 50%; ">
                            <div style="font-size: smaller; text-align: left;"><b>League Selection</b></div>
                          </div>
                          <select style="font-size: small;width: 50%;" v-model="appDataOptions[index].league">
                            <option v-for="person in leagues" :value="person.abv">{{ person.name }}</option>
                          </select>
                        </div>
                        <div id="GameSelection" style="display: inline-flex;width: 100%; padding-top: 5%">
                          <div style="width: 50%; ">
                            <div style="font-size: smaller; text-align: left;"><b>Game Selection</b></div>
                          </div>
                          <select style="font-size: small;width: 50%;" v-model="appDataOptions[index].newgamePK"
                            @focus="setActive(appData[index])">
                            <option v-for="person in gameoptionsleagues.leagues[appDataOptions[index].league]"
                              :style="person.isActive ? 'background: #F7C1C1;' : ''" :value="person.gamePk">{{
                                person.homeaway }}</option>
                          </select>
                        </div>
                        <div id="ScriptSelection" style="display: inline-flex;width: 100%; padding-top: 5%">
                          <div style="width: 50%; ">
                            <div style="font-size: smaller; text-align: left;"><b>Script</b></div>
                          </div>
                          <select style="font-size: small;width: 50%;" v-model="appDataOptions[index].scriptName">
                            <option v-for="person in scriptNamesMLB" :value="person.scriptName">{{ person.scriptName }}
                            </option>
                          </select>
                        </div>

                        <!-- // ONCLICK ON THE DROP DOWN :dropDownVisableChange -->
                        <div id="SrtUrlSelection" style="display: inline-flex;width: 100%; padding-top: 5%">
                          <div style="width: 50%; ">
                            <div style="font-size: smaller; text-align: left;"><b> SRT Url</b></div>
                          </div>
                          <select style="font-size: small;width: 50%;" v-model="appDataOptions[index].srtURL">
                            <option v-for="person in srtoptions" :value="person.url">{{ person.nick }}</option>
                          </select>
                        </div>
                        <div id="VidFormatSelection" style="display: inline-flex;width: 100%; padding-top: 5%">
                          <div style="width: 50%; ">
                            <div style="font-size: smaller; text-align: left;"><b>Video Format</b></div>
                          </div>
                          <select style="font-size: small;width: 50%;" v-model="appDataOptions[index].vidFmt">
                            <option v-for="person in videoFormats" :value="person.value">{{ person.name }}</option>
                          </select>
                        </div>
                        <div id="BroadcastConfigSelection" style="display: inline-flex;width: 100%; padding-top: 5%">
                          <div style="width: 50%; ">
                            <div style="font-size: smaller; text-align: left;"><b>Custom Config</b></div>
                          </div>
                          <select style="font-size: small;width: 50%;" v-model="appDataOptions[index].broadcastID">
                            <option v-for="person in selectedConfigLocal" :value="person.id">{{ person.broadcastName }}
                            </option>
                          </select>
                        </div>

                        <div style="width: 100%; padding-top: 5%">
                          <a-button :loading='appDataOptions[index].loading'
                            @click=sendSetupmessage(index)>Submit</a-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="!card.setting && !card.control && card.gamePK == 0 && store.state.user.organisation == 'ABS'">
                    <div id="Header" style="display: inline-flex; width: 100%">
                      <div style="white-space: nowrap; overflow: hidden; color: #36a8dd;">
                        {{ index }}
                      </div>
                    </div>
                    <img v-if="card.gamePK != 0" v-bind:src="!card.thumb
                      ? require('../assets/headlessLoad.png')
                      : 'data:image/jpeg;base64,' + card.thumb
                      " alt="No Thumbnail" class="squared" />
                    <h1 v-if="card.lostConn == 1">Connection Lost</h1>
                    <div id="teams" v-if="card.lostConn == 0" style="color: #36a8dd">
                      <div v-if="card.lostConn == 0 && appDataOptions[index] != undefined" id="status" v-bind:style="overrideStyles
                        ">
                        <h1>ABS Setup</h1>
                        <div style="width: 100%; padding-top: 5%">
                          <div>
                            <a-button :loading='appDataOptions[index].loading' @click="showModal(index)">Game
                              Selector</a-button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!card.setting && !card.control && card.gamePK != 0">
                    <div id="Header" style="display: inline-flex; width: 100%">
                      <div style="white-space: nowrap; overflow: hidden;">
                        {{ index }}
                      </div>
                      <div id="menu-icons" style="
                                                                                          justify-content: end;
                                                                                          display: flex;
                                                                                          margin-left: auto;
                                                                                          /* width: inherit; */
                                                                                          align-items: center;
                                                                                          font-size: large;
                                                                                          color: #48b5e7;
                                                                                        ">
                        <div id="menu-slider">
                          <Icon icon="icon-park-outline:setting-config" style="padding-right: 2px"
                            @click="settingsPopout(index)" />
                        </div>
                        <div id="menu-broadcast" style="padding-left: 2px">
                          <Icon icon="ph:broadcast-fill" />
                        </div>
                      </div>
                    </div>

                    <img v-bind:src="!card.thumb
                      ? require('../assets/headlessLoad.png')
                      : 'data:image/jpeg;base64,' + card.thumb
                      " alt="No Thumbnail" class="squared" />
                    <h1 v-if="card.lostConn == 1">Connection Lost</h1>
                    <div id="teams" v-if="card.lostConn == 0" style="color: #36a8dd">
                      {{ card.away.toUpperCase() }} @
                      {{ card.home.toUpperCase() }}
                      <div v-if="card.lostConn == 0" id="status" v-bind:style="card.runState != 3 ? baseStyles : overrideStyles
                        ">
                        State: {{ card.runStateDesc }}
                        <div v-bind:style="parseInt(card.currFPS) <= 40
                          ? baseStyles
                          : overrideStyles
                          ">
                          FPS: {{ Number(card.currFPS).toFixed(2) }}<br />
                          Game Status: {{ card.gameStatus }}<br />
                          State of play: {{ card.stateOfPlay }}<br />
                          <div style="font-size:small">{{ card.lastPitchDesc }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="card.control && card.lostConn == 0 && !card.setting && card.gamePK != 0">
                    <div id="Header" style="display: inline-flex; width: 100%">
                      <div v-bind:id="index + 'ControlsMenu'" class="ControlsMenu" style="
                                                                                          white-space: nowrap;
                                                                                          width: 50%;
                                                                                          text-align: center;
                                                                                        ">
                        Controls
                      </div>
                      <div v-bind:id="index + 'SettingsMenu'" class="SettingsMenu" style="
                                                                                          white-space: nowrap;
                                                                                          width: 50%;
                                                                                          text-align: center;
                                                                                          border: 0.2em solid #fff;
                                                                                        " @click="swapSelect(index)">
                        Settings
                      </div>
                    </div>
                    <div id="Modulebody"
                      style="width: 100%; height: 100%; padding-left: 5%; padding-right: 5%; font-size: medium;">
                      <div v-if="userData.groupView.includes('ABS')" id="PlayRow1"
                        style="inherit: none; display: flex; flex: flex-wrap; width: 100%; padding-top: 5%">

                        <div style="font-size: xx-large;  "><b>PLAY </b></div>

                        <div
                          style="width: 40%; margin-left: auto;display: flex;justify-content: right;align-items: center; ">
                          <a-button :disabled="!appData[index.substring(0, index.length - 1)].playButtonEnabled"
                            @click="sendMessage('PLAY', 'PLAY', index)" class="bg-blue-300" style="text-align: center;"
                            type="primary" shape="circle" size="large">&#9654</a-button>
                        </div>
                      </div>
                      <div
                        v-if="userData.groupView.includes('ABS') && appData[index.substring(0, index.length - 1)].ABSPlayRemainingSecs != 0"
                        id="PlayRow7" style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: small;"><b>Remaining ABS Sec</b></div>

                        <div style="margin-left: auto;font-size: large;"><span>{{ appData[index.substring(0,
                          index.length - 1)].ABSPlayRemainingSecs }}</span></div>
                      </div>
                      <div v-if="userData.groupView.includes('ABS')" id="PlayRow7"
                        style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: small;"><b>Secs Since Last Pitch</b></div>

                        <div v-if="appData[index.substring(0, index.length - 1)].secSinceLastPitch <= 20"
                          style=" margin-left: auto;font-size: x-large;"><span>{{ appData[index.substring(0,
                            index.length - 1)].secSinceLastPitch }}</span></div>
                        <div v-if="appData[index.substring(0, index.length - 1)].secSinceLastPitch > 20"
                          style=" margin-left: auto;font-size: x-large; color: red"><span>{{ appData[index.substring(0,
                            index.length - 1)].secSinceLastPitch }}</span></div>
                      </div>
                      <div v-if="userData.groupView.includes('ABS')" id="PlayRow5"
                        style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: small;"><b>Last pitch challenged</b></div>

                        <div v-if="appData[index.substring(0, index.length - 1)].ABSChallenge == false"
                          style=" margin-left: auto; font-size: large; color: red;"><span>No</span></div>
                        <div v-if="appData[index.substring(0, index.length - 1)].ABSChallenge == true"
                          style=" margin-left: auto;  font-size: large; color: rgb(63, 187, 63);"><span>Yes</span></div>
                      </div>
                      <div id="PlayRow2" style="display: inline-flex; width: 100%; padding-top: 5%">

                        <div style=" font-size: smaller; "><b>Playback Mode</b></div>

                        <select disabled style="width: 55%;font-size: smaller;" v-model="appData[index].playbackoption"
                          @change="sendMessage('PLAYBACK', card.settings.playbackoption, index)">
                          <option v-for="poption in playbackOptions" :value="poption.key">{{ poption.value }}</option>
                        </select>
                      </div>
                      <div id="PlayRow9" style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: small;"><b>AMQ Status</b></div>

                        <div style=" margin-left: auto; font-size: smaller; color: rgb(0, 0, 0);">
                          <span>{{ appData[index.substring(0, index.length - 1)].AMQStateDesc }}</span>
                        </div>
                      </div>
                      <div v-if="userData.groupView.includes('MLB')" id="PlayRow3"
                        style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: smaller;"><b>Pre-Roll</b></div>

                        <div
                          style="width: 40%;margin-left: auto;display: flex;justify-content: right;align-items: center; ">
                          <a-switch class="preroll" @change="sendMessage('SLATE', card.slate, index)" style=" color:black"
                            v-model:checked="card.slate">
                            <template #checkedChildren>
                              <Icon style=" color:black" icon="dashicons:yes" />
                            </template>
                            <template #unCheckedChildren>
                              <Icon style=" color:black" icon="maki:cross" />
                            </template>
                          </a-switch>
                        </div>
                      </div>
                      <div v-if="userData.groupView.includes('MLB')" id="PlayRow4"
                        style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: smaller;"><b>GFX Position</b></div>

                        <div
                          style="width: 40%; margin-left: auto;display: flex;justify-content: right;align-items: center; ">
                          <a-switch class="side" @change="sendMessage('GFXSIDE', card.gfxSide, index)"
                            v-model:checked="card.gfxSide">
                            <template #checkedChildren>
                              <Icon style=" color:black" icon="material-symbols:arrow-back-rounded" />
                            </template>
                            <template #unCheckedChildren>
                              <Icon style=" color:black" icon="material-symbols:arrow-forward-rounded" />
                            </template>
                          </a-switch>
                        </div>
                      </div>
                      <div v-if="userData.groupView.includes('MLB')" id="PlayRow3"
                        style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: smaller;"><b>GFX State</b></div>

                        <div
                          style="width: 40%; margin-left: auto;display: flex;justify-content: right;align-items: center; ">
                          <a-switch class="preroll" @change="sendOffMessage('GFXSIDE', card, index)" style=" color:black"
                            v-model:checked="card.gfxOn">
                            <template #checkedChildren>
                              <Icon style=" color:black" icon="dashicons:yes" />
                            </template>
                            <template #unCheckedChildren>
                              <Icon style=" color:black" icon="maki:cross" />
                            </template>
                          </a-switch>
                        </div>
                      </div>
                      <div v-if="userData.groupView.includes('MLB')" id="PlayRow5"
                        style="display: inline-flex;width: 100%; padding-top: 5%">

                        <div style="font-size: smaller;"><b>Environment</b></div>

                        <div
                          style="width: 40%; margin-left: auto;display: flex;justify-content: right;align-items: center; ">
                          <a-switch @change="sendMessage('NIGHTMODE', card.nightMode, index)" class="environment"
                            v-model:checked="card.nightMode">
                            <template #checkedChildren>
                              <Icon style=" color:black" icon="ph:moon-fill" />
                            </template>
                            <template #unCheckedChildren>
                              <Icon style=" color:black" icon="octicon:sun-16" />
                            </template>
                          </a-switch>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="!card.control && card.lostConn == 0 && card.setting">
                    <div id="Header" style="display: inline-flex; width: 100%">
                      <div v-bind:id="index + 'ControlsMenu'" class="ControlsMenu" style="
                                                                                          white-space: nowrap;
                                                                                          width: 50%;
                                                                                          text-align: center;
                                                                                          border: 0.2em solid #fff;
                                                                                        " @click="swapSelect(index)">
                        Controls
                      </div>

                      <div v-bind:id="index + 'SettingsMenu'" class="SettingsMenu"
                        style="white-space: nowrap;width: 50%;text-align: center;">
                        Settings
                      </div>
                    </div>
                    <div id="Modulebody"
                      style="width: 100%; height: 100%; padding-left: 5%; padding-right: 5%; font-size: medium;">
                      <div v-if="!store.state.user.organisation == 'ABS'" id="PlayRow2"
                        style="display: inline-flex;width: 100%; padding-top: 15%">
                        <div style="width: 40%">
                          <div style=" font-size: smaller; "><b>Script</b></div>
                        </div>
                        <select style="width: 60%; font-size: x-small" v-model="appData[index].settings.scriptName"
                          @change="sendMessage('scriptName', card.settings.scriptName, index)">
                          <option v-for="person in scriptNamesMLB" :value="person.scriptName">{{ person.scriptName }}
                          </option>
                        </select>
                      </div>
                      <div id="PlayRow3" style="display: inline-flex;width: 100%; padding-top: 5%">
                        <div style="width: 40%">
                          <div style="font-size: smaller;"><b>Format</b></div>
                        </div>
                        <select style="width: 60%; font-size: x-small" v-model="appData[index].settings.vidFmt"
                          @change="sendMessage('vidFmt', card.settings.vidFmt, index)">
                          <option v-for="person in videoFormats" :value="person.value">{{ person.name }}</option>
                        </select>
                      </div>
                      <div id="PlayRow4" style="display: inline-flex;width: 100%; padding-top: 5%">
                        <div style="width: 40%">
                          <div style="font-size: smaller;"><b>SRT Dest.</b></div>
                        </div>
                        <select style="width: 60%; font-size: x-small" v-model="appData[index].settings.srtURL"
                          @change="sendMessage('srtURL', card.settings.srtURL, index)">
                          <option v-for="person in srtoptions" :value="person.url">{{ person.nick }}</option>
                        </select>
                      </div>
                      <div v-if=card.nightMode id="SkyboxSelection"
                        style="display: inline-flex;width: 100%; padding-top: 5%">
                        <div style="width: 40%">
                          <div style="font-size: smaller;"><b>Skybox</b></div>
                        </div>
                        <select style="width: 60%; font-size: x-small" v-model="appData[index].settings.skybox"
                          @change="sendMessage('skybox', card.settings.skybox, index)">
                          <option v-for="person in skyboxConfigLocal[0].Night" :value="person.type + '/' + person.file">{{
                            person.nick }}</option>
                        </select>
                      </div>
                      <div v-if=!card.nightMode id="SkyboxSelection"
                        style="display: inline-flex;width: 100%; padding-top: 5%">
                        <div style="width: 40%">
                          <div style="font-size: smaller;"><b>Skybox</b></div>
                        </div>
                        <select style="width: 60%; font-size: x-small" v-model="appData[index].settings.skybox"
                          @change="sendMessage('skybox', card.settings.skybox, index)">
                          <option v-for="person in skyboxConfigLocal[0].Day" :value="person.type + '/' + person.file">{{
                            person.nick }}</option>
                        </select>
                      </div>
                      <!-- <div v-if="store.state.user.organisation == 'ABS'" id="PlayRow6"
                        style="display: inline-flex;justify-content: center;width: 100%; padding-top: 5%">
                        <div>
                          <a-button @click="console.log(':)')">
                            <div style="font-size: smaller;"><b>Show AMQ Log</b></div>
                          </a-button>
                        </div>
                        <div>
                          <a-button>
                            <template #icon>
                              <FullscreenOutlined />
                            </template>
                          </a-button>
                        </div> 
                      </div> -->
                      <!-- <div id="PlayRow1" style="display: inline-flex;width: 100%; padding-top: 5%"> 
                        <div style="width: 60%; ">
                          <div style="font-size: smaller;"><b>Auto Shutdown</b></div>
                        </div>
                        <div class="switch" style="margin: auto; "><span class="slider round"
                            @click="listselect()"></span></div>
                      </div> -->

                      <!-- <div id="PlayRow4" style="display: inline-flex;width: 100%; padding-top: 5%">
                        <div style="width: 40%">
                          <div style="font-size: smaller;"><b>SRT Dest.</b></div>
                        </div>
                      </div> -->
                    </div>
                    <div id="PlayRow7"
                      style="display: inline-flex; width: 100%; flex-wrap: wrap; padding-top: 5%;font-size: smaller;display: flex;justify-content: center;">
                      <div style="padding-left: 2%; padding-right: 2%;">
                        <a-popconfirm title="Are you sure you want to restart SC3D?" ok-text="Yes" cancel-text="No"
                          @confirm="sendMessage('SC3D', 'RESTART', index)">
                          <a-button style="font-size: smaller;" size=small type="primary" ghost>Restart SC3D</a-button>
                        </a-popconfirm>
                      </div>

                      <div style="padding-left: 2%; padding-right: 2%;">
                        <a-popconfirm title="Are you sure you want to stop SC3D?" ok-text="Yes" cancel-text="No"
                          @confirm="sendMessage('SC3D', 'STOP', index)">
                          <a-button style="font-size: smaller; " size=small danger ghost>Stop SC3D</a-button>
                        </a-popconfirm>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="listview" class="wrapper mt-5">
            <div id="app">
              <table style="width: 100%">
                <tr>
                  <th>Feed</th>
                  <th>Machine</th>
                  <th>gamePk</th>
                  <th>Game</th>
                  <th>State</th>
                  <th>AMQ</th>
                  <th>Script</th>
                  <th>Format</th>
                  <th>SRT</th>
                  <th>FPS</th>
                  <th>Status</th>
                  <th>Problem</th>
                </tr>
                <template v-for="(card, index) in appData">
                  <template v-if="card.instanceID">
                    <tr v-bind:style="card.lostConn == 1 ? grey : normal" v-bind:id="index"
                      class="card-item rounded-md box-border">
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        <img v-bind:src="'data:image/jpeg;base64,' + card.thumb" alt="No Thumbnail" class="squared" />
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        <div id="clickable">
                          {{ index }}
                        </div>
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.gamePK }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        <div>
                          {{ card.away.toUpperCase() }} @
                          {{ card.home.toUpperCase() }}
                        </div>
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.gameStatus }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.AMQStateDesc }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.scriptName }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.vidFmtDesc }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.outputNick }}<br />
                        {{ card.srtURL }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.currFPS }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.runStateDesc }}
                      </td>
                      <td style="
                                                                                      text-align: center;
                                                                                      vertical-align: middle;
                                                                                      border: 1px solid grey;
                                                                                      border-collapse: collapse;
                                                                                    ">
                        {{ card.problem }}
                      </td>
                    </tr>
                  </template>
                </template>
              </table>
              <!-- <button @click="addCard">Add card</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div><a-modal v-model:visible="visible" width="800px" title="ABS Setup">
    <div class="columns" style="display: flex;flex-direction: row;flex-wrap: wrap;width: 100%;">
      <div class="column" style="flex: 50%;">
        <div>
          <div style="font-size: medium;width: 80%;"><b>League Selection&#9;</b></div>
          <select style="font-size: medium;width: 80%;" v-model="appDataOptions[selectedAbsStart].league">
            <option v-for="person in leagues" :value="person.abv">{{ person.name }}</option>
          </select>
        </div>
        <div>
          <div style="font-size: medium;width: 80%;"><b>Srt Url&#9;</b></div><select style="font-size: medium;width: 80%;"
            v-model="appDataOptions[selectedAbsStart].srtURL">
            <option v-for="person in srtoptions" :value="person.url">{{ person.nick }}</option>
          </select>
        </div>

      </div>
      <div class="column" style="flex: 50%;">
        <div>
          <div style="font-size: medium;width: 80%;"><b>Script&#9;</b></div>
          <select style="font-size: medium;width: 80%;" v-model="appDataOptions[selectedAbsStart].scriptName">
            <option v-for="person in scriptNamesABS" :value="person.scriptName">{{
              person.scriptName }}
            </option>
          </select>
        </div>
        <div>
          <div style="font-size: medium;width: 80%;"><b>Video Format&#9;</b></div><select
            style="font-size: medium;width: 80%;" v-model="appDataOptions[selectedAbsStart].vidFmt">
            <option v-for="person in videoFormats" :value="person.value">{{ person.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <a-table :rowKey="record => record.gamePk" :columns="columns" :rowSelection="rowSelection"
      :data-source="gameoptionsleagues.leagues[appDataOptions[selectedAbsStart].league]" size="small" />
    <template #footer>
      <a-button key="back" @click="handleCancel">Return</a-button>
      <a-button key="submit" @click="handleOk(selectedAbsStart)">Confirm</a-button>
    </template>
  </a-modal>
</template>


<script setup>
import { Icon } from "@iconify/vue";
import { Auth, PubSub, Amplify } from "aws-amplify";
import { AuthState } from "@aws-amplify/ui-components";
import { AWSIoTProvider, MqttOverWSProvider } from "@aws-amplify/pubsub";
import { IoTClient, AttachPolicyCommand } from "@aws-sdk/client-iot";
import Loading from "vue-loading-overlay";
import moment from "moment";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { ref, onUnmounted, onMounted, reactive, computed } from "vue";
import awsconfig from "../aws-exports";
import store from '../store';
import { FullscreenOutlined } from "@ant-design/icons-vue";
import { ec2PushMess, getMyProfile, getSrtConfig, getBroadcastConfig } from "../graphql/graphqlActions";
import skyboxconfig from "../skyboxconfig";


if (Amplify.PubSub._pluggables.length == 0) {
  const old = MqttOverWSProvider.prototype._onMessage;
  MqttOverWSProvider.prototype._onMessage = function (topic, msg) {
    old.call(this, topic, JSON.stringify({ msg: msg }));
  };
}
const selectedRowKey = ref([]);
const renderContent = ({ text, index }) => {
  const obj = {
    children: new Date(text).toLocaleString()
  };
  return obj;
};

const rowSelection = ref({
  selectedRowKey,
  onChange: (selectedRowKeys) => {
    selectedRowKey.value = selectedRowKeys;
  }, type: 'radio'
});
const handleCancel = () => {
  visible.value = false;
};

const scriptNamesMLB = ref([
  { scriptName: "autoPitch" },
  { scriptName: "autoHit" },
  { scriptName: "MLBN_LastPitch" },
  { scriptName: "PIT_LastPitch" },
  { scriptName: "LP_MASN" },
  { scriptName: "LP_TBS" },
  { scriptName: "miniMap" }
]);

const scriptNamesABS = ref([
  { scriptName: "ABS_Cloud" },
]);
const leagues = ref([
  { name: "Main League (MLB)", value: 1, abv: "MLB" },
  { name: "Triple A Minor League", value: 11, abv: "AAA" },
  { name: "Minor League Baseball", value: 21, abv: "Minors" },
  { name: "College Baseball", value: 22, abv: "College" },
  { name: "High School Baseball", value: 586, abv: "H.S." }
]);
const playbackOptions = ref([
  { key: "MANUAL", value: "Manual" },
  { key: "AUTO", value: "Automatic" }
]);
const videoFormats = ref([
  { name: "720p 59.94", value: 4 },
  { name: "1080p 59.94", value: 12 },
]);

const columns = [
  {
    title: 'Game PK',
    dataIndex: 'gamePk',
    key: 'gamePk',
  },
  {
    title: 'Venue',
    dataIndex: ['venue', 'name'],
  },
  {
    title: 'Shortform',
    dataIndex: 'homeaway',
  },
  {
    title: 'Date',
    dataIndex: 'gameDate',
    customRender: renderContent,
  },
  {
    title: 'Status',
    dataIndex: ['status', 'detailedState'],
  },
];

const selectedAbsStart = ref();
const selectedConfigLocal = ref([]);
const srtID = ref();
const listview = ref(false);
const srtoptions = ref([]);
const skyboxConfigLocal = ref(skyboxconfig.SkyboxOptions);
const gameoptionsleagues = ref(null);
const settingscard = ref({
  "background-color": "#eceaeb",
  padding: 0,
  width: "80%",
  height: "100%",
});
const baseStyles = ref({
  color: "red",
});
const overrideStyles = ref({
  color: "black",
});
const grey = ref({
  opacity: 0.4,
});
const normal = ref({
  opacity: 1,
});
const userData = ref(null);
const info = ref(null);
const user = ref(null);
const authState = ref({});
const subIot = ref({});
const isLoading = ref(true);
isLoading.value = true;
const appData = ref({});
const appDataOptions = ref({});
const userprof = ref(null);
const interval = ref(null);
const disabledGfx = ref(false);
const srtTable = ref(null);
const broadcastTable = ref(null);
const visible = ref(false);
const showModal = (index) => {
  visible.value = true;
  selectedAbsStart.value = index
};


user.value = userData.value || {};
onMounted(async () => {
  await getUser();
  userprof.value = await getMyProfile();
  addPluggable();
  retrieveSrt()
  broadcastRetrieve()
})


async function handleOk(index) {
  appDataOptions.value[index].loading = true;
  appDataOptions.value[index].newgamePK = selectedRowKey.value[0]
  await sendSetupmessage(index)
  selectedRowKey.value = [];
  visible.value = false;
};


const subIotExists = Amplify.PubSub._pluggables.length > 0;
let dateNow;
if (moment().tz("America/New_York").format("H") < 5) {
  dateNow = moment().tz("America/New_York").subtract(1, 'days').format("MM/DD/YYYY");
} else {
  dateNow = moment().tz("America/New_York").format("MM/DD/YYYY");
}

interval.value = setInterval(() => {
  lostConnCheck();
}, 10000);

function getClass() {
  if (store.state.user.selectedRole == "ABSViewer") {
    return "card-list-VW";
  } else { return "card-list-AC" }
}

getGamesLeague(dateNow);
if (JSON.parse(localStorage.getItem("Ec2Object") == null) || JSON.parse(localStorage.getItem("Ec2ObjectUpdateTime")) + 300000 < Date.now()) {
  // console.log("Not Within threshold, new object init")
  appData.value = {};
} else {
  appData.value = JSON.parse(localStorage.getItem("Ec2Object"));
  // console.log("Within threshold, holding on to object")
}

// "postInstances('reboot', card.id)">
// ec2Actions('reboot',id,store.state.user.organisation)

async function retrieveSrt() {
  try {
    let test = await getSrtConfig(store.state.user.organisation)
    await loadSrtValues(test)

    if (store.state.user.selectedRole.includes("Viewer") && store.state.user.organisation == "ABS") {
      try {
        matchSrtToAllowedEc2s();
      } catch (error) {
        console.log(error)
      }
    }
  } catch (e) {
    console.error(new Error("SRT retrieval error"))
  }
}

async function loadSrtValues(test) {
  srtoptions.value = JSON.parse(test.getSrtConfig).OutputDestinations
  srtTable.value = JSON.parse(test.getSrtConfig).tableName
  srtID.value = JSON.parse(test.getSrtConfig).srtId
  return
}
// get user and allowed ec2s
// retrieve srts that only match the instances
function matchSrtToAllowedEc2s() {
  // review this
  let profVal = userprof.value.ec2s //expected "['x','y']"
  let res = srtoptions.value.filter(({ nick }) => profVal.replace(/[_ ]+/g, '').includes(nick.replace(/[_ ]+/g, '')));
  srtoptions.value = res
  return
}

async function broadcastRetrieve() {
  try {
    let configlist = await getBroadcastConfig(store.state.user.organisation);
    selectedConfigLocal.value = JSON.parse(configlist.getBroadcastConfig);
    broadcastTable.value = JSON.parse(configlist.getBroadcastConfig)[0].tableName
  } catch (e) {
    console.error(new Error("Broadcast Config retrieval error"))
  }
}

function setActive(key) {
  let activePKs = [];
  if (key.league == "") {
    return
  }
  for (const [element, i] of Object.entries(appData.value)) {
    activePKs.push(i.gamePK)
  }
  gameoptionsleagues.value.leagues[key.league].forEach((element, i) => {
    if (activePKs.includes(element.gamePk)) {
      gameoptionsleagues.value.leagues[key.league][i].isActive = true;
    }
  })
};

function listselect() {
  listview.value = !listview.value
};

function settingsPopout(index) {

  if (appData.value[index].lostConn == 1) {
    return
  }
  let machineName = index + "!";
  let gfx = false
  if (appData.value[machineName] == undefined) {
    if (appData.value[index].gfxSide == "L") {
      gfx = true
    }
    let playbackMode = 'MANUAL';
    if (userData.value.groupView.includes('MLB')) {
      playbackMode = 'AUTO'
    }
    //Object.assign(appData.value[data.machineName], { "league": "", "loading": false, "newgamePK": 0 });
    appData.value[machineName] = { "machineName": machineName, "setting": false, "control": true, "lostConn": 0, "settings": appData.value[index], "gfxOn": appData.value[index].gfxOn, "gfxSide": gfx, "nightMode": appData.value[index].nightMode, "slate": appData.value[index].slateOn, "playbackoption": playbackMode }

    sort();
    //console.log(appData.value[machineName])
  } else {
    delete appData.value[machineName]
  }
};

function swapSelect(index) {
  let machineName = index;
  if (appData.value[machineName].setting == true) {
    appData.value[machineName].setting = false
    appData.value[machineName].control = true
  } else if (appData.value[machineName].control == true) {
    appData.value[machineName].setting = true
    appData.value[machineName].control = false
  }
};

function lostConnCheck() {
  let currTime = new Date().getTime();
  var results = Object.keys(appData.value).reduce((acc, val) => {
    if (appData.value[val].lostConn === 0 && !appData.value[val].machineName.includes("!") && appData.value[val].gamePK !== 0) acc[val] = appData.value[val];
    return acc;
  }, {});
  localStorage.setItem("Ec2ObjectUpdateTime", JSON.stringify(currTime));
  localStorage.setItem("Ec2Object", JSON.stringify(results));
  for (var key in appData.value) {
    if (currTime - appData.value[key].lstMsgTime > 15000) {
      // if (appData.value[key].lostConn == 1) {
      //   document.getElementById("out-click").click();
      //   delete appData.value[key];
      //   continue
      // }
      appData.value[key].lostConn = 1;
      if (appData.value[key + '!']) {
        delete appData.value[key + '!']
      }
    } else {
      appData.value[key].lostConn = 0;
    }
  }
};

async function getGames(data) {
  let gamedata = {};
  if (
    appData.value[data.machineName] &&
    data.gamePK == appData.value[data.machineName].gamePK
  ) {
    gamedata = {
      home: appData.value[data.machineName].home,
      away: appData.value[data.machineName].away,
    };
    return gamedata;
  }
  await axios
    .get(
      "https://statsapi.mlb.com/api/v1.1/game/" + data.gamePK + "/feed/live"
    )
    .then((response) => {
      if (Object.keys(response.data.gameData.players).length === 0) {
        gamedata = { home: "nn", away: "nn" };
      } else {
        gamedata = {
          home: response.data.gameData.teams.home.abbreviation,
          away: response.data.gameData.teams.away.abbreviation,
        };
      }
    });
  return gamedata;
};

async function sendSetupmessage(index) {
  appDataOptions.value[index].loading = true
  let action = { "scriptName": appDataOptions.value[index].scriptName, "srtID": srtID.value, "srtTable": srtTable.value, "srtURL": appDataOptions.value[index].srtURL, "vidFmt": appDataOptions.value[index].vidFmt, "gamePK": appDataOptions.value[index].newgamePK, "broadcastTable": broadcastTable.value, "broadcastID": appDataOptions.value[index].broadcastID, "skybox": appDataOptions.value[index].Skybox, }
  await ec2PushMess(JSON.stringify(action), "SETTINGS", appDataOptions.value[index].instanceID, store.state.user.organisation)
};

async function sendMessage(appliedNEW, newval, index) {
  if (appliedNEW == 'GFXSIDE') {
    if (newval == true) {
      newval = "L"
    } else if (newval == false) {
      newval = "R"
    }
  }
  let obj = { [appliedNEW]: newval }
  //appDataOptions.value[index].loading = true
  await ec2PushMess(JSON.stringify(obj), "SETTINGS", appData.value[index].settings.instanceID, store.state.user.organisation)
};
async function sendOffMessage(appliedNEW, newval, index) {
  if (appliedNEW == 'GFXSIDE') {
    // console.log(newval)
    if (newval.gfxOn == true) {
      disabledGfx.value = false
      sendMessage(appliedNEW, newval.gfxSide, index)
      return

    } else if (newval.gfxOn == false) {
      newval = "OFF"
      disabledGfx.value = true
    }
  }
  let obj = { [appliedNEW]: newval }
  //appDataOptions.value[index].loading = true
  await ec2PushMess(JSON.stringify(obj), "SETTINGS", appData.value[index].settings.instanceID, store.state.user.organisation)
};

// async getSchedule(date) {
//   let gamedata = {};
//   await axios
//     .get("https://statsapi.mlb.com/api/v1/schedule?sportId=1&date=" + date)
//     .then((response) => {
//       try {
//         gameoptions.value = response.data.dates[0].games;
//         gameoptions.value.push({ gamePk: 0, venue: { name: "Clear" } });
//         //console.log(response.data)
//       } catch { }
//     });
//   return gamedata;
// },

function sort() {
  let ordered = Object.keys(appData.value)
    .sort()
    .reduce((obj, key) => {
      obj[key] = appData.value[key];
      return obj;
    }, {});
  appData.value = ordered;
};

//WHY DO THEY KEEP GETTING ME TO ADD THINGS AND QUICKLY INSTEAD OF CORRECTLY WAITING
async function getGamesLeague(date) {
  let gamedata = {};
  let temparray = { "leagues": {} }
  let gameabv = {}
  await axios
    .get("https://statsapi.mlb.com/api/v1/schedule?sportId=1&date=" + date)
    .then((response) => {
      try {
        temparray.leagues = { ...temparray.leagues, ...{ "MLB": response.data.dates[0].games } };
        //temparray.leagues.MLB.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/schedule?sportId=11&date=" + date)
    .then((response) => {
      try {
        temparray.leagues = { ...temparray.leagues, ...{ "AAA": response.data.dates[0].games } };
        //temparray.leagues.AAA.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/teams?sportIds=1&fields=teams,abbreviation,id")
    .then((response) => {
      try {
        gameabv = response.data.teams;
        //temparray.leagues.AAA.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/teams?sportIds=11&fields=teams,abbreviation,id")
    .then((response) => {
      try {
        gameabv = gameabv.concat(response.data.teams);
        //temparray.leagues.AAA.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/schedule?sportId=586&date=" + date)
    .then((response) => {
      try {
        temparray.leagues = { ...temparray.leagues, ...{ "H.S.": response.data.dates[0].games } };
        //temparray.leagues.MLB.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/schedule?sportId=22&date=" + date)
    .then((response) => {
      try {
        temparray.leagues = { ...temparray.leagues, ...{ "College": response.data.dates[0].games } };
        //temparray.leagues.AAA.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/teams?sportIds=586&fields=teams,abbreviation,id")
    .then((response) => {
      try {
        gameabv = response.data.teams;
        //temparray.leagues.AAA.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/teams?sportIds=22&fields=teams,abbreviation,id")
    .then((response) => {
      try {
        gameabv = gameabv.concat(response.data.teams);
        //temparray.leagues.AAA.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/schedule?sportId=21&date=" + date)
    .then((response) => {
      try {
        temparray.leagues = { ...temparray.leagues, ...{ "Minors": response.data.dates[0].games } };
        //temparray.leagues.MLB.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  await axios
    .get("https://statsapi.mlb.com/api/v1/teams?sportIds=21&fields=teams,abbreviation,id")
    .then((response) => {
      try {
        gameabv = response.data.teams;
        //temparray.leagues.AAA.push({ gamePk: 0, venue: { name: "Clear" } });
      } catch { }
    });
  //console.log(gameabv)
  gameoptionsleagues.value = temparray
  for (const [key, value] of Object.entries(gameoptionsleagues.value.leagues)) {
    gameoptionsleagues.value.leagues[key].forEach((element, i) => {
      let away;
      let home;
      try {
        away = gameabv.find(o => o.id === element.teams.away.team.id).abbreviation
      } catch {
        away = element.teams.away.team.name
      }
      try {
        home = gameabv.find(o => o.id === element.teams.home.team.id).abbreviation
      } catch {
        home = element.teams.home.team.name
      }
      gameoptionsleagues.value.leagues[key][i] = { ...gameoptionsleagues.value.leagues[key][i], ...{ "homeaway": away + ' @ ' + home } }
    });
  }
  return gamedata;
};

function addPluggable() {
  if (Amplify.PubSub._pluggables.length == 0) {
    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: awsconfig.Iot.region,
        aws_pubsub_endpoint: awsconfig.Iot.endpoint,
      })
    );
  }
};

async function getUser() {
  await Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        userData.value = data;
        userData.value.groupView = store.state.user.selectedRole;
        // userData.value.groupView = userData.value.signInUserSession.accessToken.payload['cognito:groups'][0]
        authState.value = AuthState.SignedIn;
        return;
      }
    })
    .catch(() => {
      userData.value = null;
      return;
    });
};

onUnmounted(() => {
  clearInterval(interval.value);
  subIot.value.unsubscribe();
  subIot.value = null;
})

// unsubscribeAuth.value = onAuthUIStateChange(async (authState, authData) => {
// authState.value = authState;     //this is completed in
// sendMessage();
// if (subIotExists && (authState.value === AuthState.SignedIn ||authState.value === AuthState.SignedOut)) {
//   console.log("unsub");
//   subIot.value.unsubscribe();
//   info.value = null;
// }
if (
  user.value != null &&
  store.state.user.user.signInUserSession &&
  info.value === null
) {
  //console.log(Auth.currentCredentials());
  //This below line gets temporary credentials from Cognito Identity pools, may not be needed..
  Auth.currentCredentials().then(async (info) => {
    info.value = info;
    try {
      const client = new IoTClient({
        credentials: {
          accessKeyId: info.accessKeyId,
          secretAccessKey: info.secretAccessKey,
          expiration: info.expiration,
          sessionToken: info.sessionToken,
        },
        region: awsconfig.Amplify.Auth.region,
      });
      const command = new AttachPolicyCommand({
        policyName: awsconfig.Iot.policyName,
        target: info.identityId,
      });
      await client.send(command);
    } catch (error) {
      const { requestId, cfId, extendedRequestId } = error.$metadata;
      console.error({ requestId, cfId, extendedRequestId });
      /**
       * The keys within exceptions are also parsed.
       * You can access them by specifying exception names:
       * if (error.name === 'SomeServiceException') {
       *     const value = error.specialKeyInException;
       * }
       */
    }
    // if (!subIotExists || (subIotExists && subIot.value.closed)) {
    // if (subIotExists) {
    //console.log("sub");
    //console.log(PubSub);//

    //!SubIOT to establish the connection between IOT core and the client, does not affect the publish
    subIot.value = PubSub.subscribe(awsconfig.Iot.topicName + store.state.user.organisation).subscribe({
      next: async (packet) => {
        // let data = JSON.parse(packet.value.msg); split when a non json obj coming through (prev = 'hmmstat||{JSON}'') 
        let data = JSON.parse(packet.value.msg);
        if (!userprof.value.ec2s.includes(data.instanceID) && !userprof.value.ec2s.includes('*') && !userprof.value.ec2s.includes(data.machineName)) {
        }
        else if (userprof.value.ec2s.includes(data.instanceID) || userprof.value.ec2s.includes('*') || userprof.value.ec2s.includes(data.machineName)) {
          let gameInfo = await getGames(data);
          Object.assign(gameInfo, {
            lstMsgTime: new Date().getTime(),
            lostConn: 0,
            setting: false,
            control: false,
          });
          Object.assign(data, gameInfo);
          let message = data;
          if (message.thumb == "same") {
            try {
              message.thumb = appData.value[data.machineName].thumb
            }
            catch {
            }
          }
          appData.value[data.machineName] = message;
          let booltest = false
          if (appDataOptions.value[data.machineName] != appData.value[data.machineName].gamePK) {
            booltest = true
          }
          if (!appDataOptions.value[data.machineName] && message.gamePK == 0) {
            appDataOptions.value[data.machineName] = message;
            Object.assign(appDataOptions.value[data.machineName], { "league": "", "loading": false, "newgamePK": 0 });
            appDataOptions.value[data.machineName] = appDataOptions.value[data.machineName]
          } else if (appDataOptions.value[data.machineName] && message.gamePK == 0) {
            if (booltest == true) {
              if (appData.value[data.machineName + '!']) {
                delete appData.value[data.machineName + '!']

              }
            }
          } else if (appDataOptions.value[data.machineName] && message.gamePK != 0) {

            appDataOptions.value[data.machineName] = message;
            Object.assign(appDataOptions.value[data.machineName], { "league": "", "loading": false, "newgamePK": 0 });
            appDataOptions.value[data.machineName] = appDataOptions.value[data.machineName]
          }
          // Object.assign(message, appData.value);
          sort();
          //clickListen(data.machineName, data.instanceID);
        }
      }
      ,
      error: (error) => { console.error("IOT ERROR: Please contact Administrator with the following details - "), console.error(error) },
      complete: () => console.log("Done"),
    });
    isLoading.value = false;
    // }
  });
}
    // });
</script>
<style>
#menu-slider:hover {
  color: rgb(10, 143, 204);
  cursor: pointer;
}

#menu-gear:hover {
  color: rgb(10, 143, 204);
  cursor: pointer;
}

#menu-broadcast:hover {
  color: rgb(10, 143, 204);
  cursor: pointer;
}

.SettingsMenu {
  cursor: pointer;
}

.ControlsMenu {
  cursor: pointer;
}

.SettingsMenu:hover {
  color: rgb(10, 143, 204);
}

.ControlsMenu:hover {
  color: rgb(10, 143, 204);
}

.environment.ant-switch-checked {
  background-color: rgb(117, 199, 247) !important;
  border-color: rgb(143, 143, 143) !important;
}

.preroll.ant-switch-checked {
  background-color: rgb(117, 199, 247) !important;
  border-color: rgb(143, 143, 143) !important;
}

.side.ant-switch {
  background-color: rgb(117, 199, 247) !important;
  border-color: rgb(143, 143, 143) !important;
}
</style>